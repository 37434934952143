import React from 'react';
import { StreamChat } from 'stream-chat';
import { Chat, Channel, ChannelHeader, MessageInput, MessageInputSmall, VirtualizedMessageList, Window } from 'stream-chat-react';

import 'stream-chat-react/dist/css/index.css';

const chatClient = StreamChat.getInstance('svdujhdyx5u4');
const userToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoib3JhbmdlLW1vZGUtOSJ9.tfjdtN0DhI4fxipQ7o7NXg_hdfTpqlmKJIBgooDNbN8';

chatClient.connectUser(
  {
    id: 'orange-mode-9',
    name: 'orange-mode-9',
    image: 'https://getstream.io/random_png/?id=orange-mode-9&name=orange-mode-9',
  },
  userToken,
);

const channel = chatClient.channel('livestream', 'spacex', {
  image: 'https://goo.gl/Zefkbx',
  name: 'Live Chat',
});

const App = () => (
  <Chat client={chatClient} theme='livestream light'>
    <Channel channel={channel}>
      <Window>
        <ChannelHeader live />
        <VirtualizedMessageList />
        <MessageInput Input={MessageInputSmall} focus />
      </Window>
    </Channel>
  </Chat>
);

export default App;
